import WebSocketAsPromised from 'websocket-as-promised';
import { Config } from '@/shared/config.shared';
export const WebsocketConnection = {
    async getConnection() {
        const token = 'fromstore';
        if(token) {
            const wsUrl = `${Config.wsUrl}?token=${token}`;
            return new WebSocketAsPromised(wsUrl);
        } else {
            return new WebSocketAsPromised(Config.wsUrl);
        }
    }
        /* const wsp = new WebSocketAsPromised(wsUrl, {
            packMessage: data => JSON.stringify(data),
            unpackMessage: data => JSON.parse(data)
          }); */
}