
import { IonPage, IonHeader, IonToolbar, IonTitle, IonContent } from '@ionic/vue';
import { websocket } from './../composables/websocket';

export default  {
  name: 'Home',
  mounted: () => {
    console.log('Before component is created');
  },
  created: () => {
    console.log('After component is created.')
    const { initWs } = websocket();
    initWs();
  },
  methods: {
    ionViewDidEnter() {
      console.log('Home page did enter');
    },
    ionViewDidLeave() {
      console.log('Home page did leave');
    },
    ionViewWillEnter() {
      console.log('Home page will enter');
    },
    ionViewWillLeave() {
      console.log('Home page will leave');
    }
  },
  components: { IonHeader, IonToolbar, IonTitle, IonContent, IonPage },
  setup() {
    const { sendMessage } = websocket();

    return {
      sendMessage
    }
  }
}
