import { WebsocketConnection } from '@/shared/ws.service.shared'
export function websocket() {
    const initWs = async() => {
        const wsConn = await WebsocketConnection.getConnection();
        setInterval(() => {
            WebsocketConnection.getConnection();
        }, 30000)
        try {
            const openEvent = await wsConn.open();
            if(openEvent) {
                console.log(openEvent)
                console.log('Successfully connected to the echo websocket server...');
            }
        } catch(errors) {
            console.log(errors);
        }
        
        wsConn.onMessage.addListener((event) => {
                console.log(`On message: ${event}`);
        });

        wsConn.onError.addListener((event: any) => {
            console.log(`On error: ${event}`);
        });
        
        const closeEvent = await wsConn.close();
        if (closeEvent) {
            console.log(`On close: ${closeEvent}`);
        }

    }

    const sendMessage = async(message: any) => {
        const wsConn = await WebsocketConnection.getConnection();
        wsConn.send(message);
    }

    return {
        initWs,
        sendMessage,
    }
}